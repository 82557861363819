import React from 'react';

import Head from 'next/head';
import { Thing } from 'schema-dts';

import { getCanonical, getSeoDescr, getSeoImage, getSeoTitle } from './utils';

interface Props {
  title?: string;
  image?: string;
  noIndex?: boolean;
  noSuffix?: boolean;
  metaTitle?: string;
  metaDescr?: string;
  canonicalUrl?: string;
  children?: React.ReactNode;
  schemas?: Thing[];
}

const Seo = ({ image, noIndex, noSuffix, schemas, ...rest }: Props) => {
  const pageTitle = getSeoTitle(rest.title ?? rest.metaTitle, noSuffix);
  const metaTitle = getSeoTitle(rest.metaTitle ?? rest.title, noSuffix);
  const metaDescr = getSeoDescr(rest.metaDescr, noSuffix);
  const canonical = getCanonical(rest.canonicalUrl);
  const metaImage = image ?? getSeoImage(null);

  return (
    <Head>
      <title key="meta:title">{pageTitle}</title>
      <meta key="meta:description" name="description" content={metaDescr} />

      {/* ItemProps */}
      <meta key="microdata:name" itemProp="name" content={metaTitle} />
      <meta key="microdata:image" itemProp="image" content={metaImage} />
      <meta key="microdata:descr" itemProp="description" content={metaDescr} />
      {canonical && <meta key="microdata:url" itemProp="url" content={canonical} />}

      {/* OpenGraph */}
      <meta key="og:type" property="og:type" content="ecommerce" />
      <meta key="og:site_name" property="og:site_name" content="mon-marché" />
      <meta key="og:title" property="og:title" content={metaTitle} />
      <meta key="og:image" property="og:image" content={metaImage} />
      <meta key="og:description" property="og:description" content={metaDescr} />
      {canonical && <meta key="og:url" property="og:url" content={canonical} />}

      {/* Twitter */}
      <meta key="twitter:card" name="twitter:card" content="summary" />
      <meta key="twitter:site" name="twitter:site" content="@monmarchefr" />
      <meta key="twitter:title" name="twitter:title" content={metaTitle} />
      <meta key="twitter:image" name="twitter:image" content={metaImage} />
      <meta key="twitter:description" name="twitter:description" content={metaDescr} />
      {canonical && <meta key="twitter:url" name="twitter:url" content={canonical} />}

      {/* Indexation et balises customs */}
      <meta key="meta:robots" name="robots" content={noIndex ? 'noindex' : 'index, follow'} />
      {canonical && <link key="meta:canonical" rel="canonical" href={canonical} />}

      {/* JSON-LD */}
      {schemas?.map((schema, index) => {
        if (typeof schema !== 'object') return null;
        const key = `json-ld/${schema['@type']}/${index}`;
        const html = { __html: JSON.stringify({ '@context': 'https://schema.org', ...schema }) };

        return <script key={key} type="application/ld+json" dangerouslySetInnerHTML={html} />;
      })}

      {rest.children}
    </Head>
  );
};

export default Seo;
