import { getImageUrlFromFormat } from 'src/common/utils/cloudinary';

import { logger } from '~/src/common/services/Logger';
import { ImageParams, Image as ImageType, ImageFormatIds } from '~/src/common/typings/image';
import { CarteMarketing } from '~/src/queries/cms/generated/cms';

import * as S from './layout';

interface Props {
  marketingCard: CarteMarketing;
}

const ImageDispatcher = ({ imageJson }: { imageJson: string }) => {
  try {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment  -- auto-ignored when updating eslint
    const image: ImageType = JSON.parse(imageJson);
    const imageUrl = getImageUrlFromFormat(
      image,
      ImageFormatIds.CardRatio,
      ImageParams.listingCard,
    );

    return <S.Image src={imageUrl} $objectFit="cover" alt={image.alt} />;
  } catch (error) {
    logger.error('JSON Exception', { originalError: error as SyntaxError });

    return null;
  }
};

const MarketingCardContent = ({ marketingCard }: Props) => {
  const { image, description, title } = marketingCard;

  return (
    <S.Container>
      {image?.media ? <ImageDispatcher imageJson={image.media} /> : null}
      <S.MarketingCardPanel>
        <S.MarketingCardTextContainer>
          <S.MarketingCardHeader>{title}</S.MarketingCardHeader>
          <S.MarketingCardDescription>{description}</S.MarketingCardDescription>
        </S.MarketingCardTextContainer>
      </S.MarketingCardPanel>
    </S.Container>
  );
};

export default MarketingCardContent;
