import React from 'react';

import ProductCard from '~/src/common/components/GridCard/ProductCard';
import RecipeCard from '~/src/common/components/GridCard/RecipeCard/RecipeCard';
import { CardInfoWithoutPositionProperties } from '~/src/common/services/Tracker';
import { AddProductBodyProductAddToCartSource } from '~/src/queries/api-ecom/generated/api-ecom.schemas';
import { isMarketingCard, isProduct, isRecipe } from '~/src/typings/products/guards';
import { CatalogItem } from '~/src/typings/products/types';

import MarketingCard from '../GridCard/MarketingCard';

interface Props {
  product: CatalogItem;
  eventSource: AddProductBodyProductAddToCartSource;
  categoryName?: string;
  subcategory?: { id: string; name: string };
  onRefresh?: () => Promise<unknown>;
  openPanelProductId: string | null;
  setOpenPanelProductId: (productId: string | null) => void;
  onClick?: (product: CatalogItem) => void;
  analyticsProperties: CardInfoWithoutPositionProperties;
}

const CardDispatcher = ({
  product: productItem,
  eventSource,
  categoryName,
  subcategory,
  onRefresh,
  openPanelProductId,
  setOpenPanelProductId,
  onClick,
  analyticsProperties,
}: Props) => {
  return (
    <>
      {isProduct(productItem) ? (
        <ProductCard
          key={productItem.id}
          product={productItem}
          eventSource={eventSource}
          categoryName={categoryName}
          subcategory={subcategory}
          // TODO: Fix this the next time the file is edited.
          // eslint-disable-next-line @typescript-eslint/no-misused-promises
          onRefresh={onRefresh}
          isDeliveryPanelOpen={openPanelProductId === productItem.id}
          onDeliveryPanelOpen={productId => setOpenPanelProductId(productId)}
          onProductCardClick={() => onClick?.(productItem)}
          analyticsProperties={{
            'product position in the list': productItem.indexes.byType,
            'card position in the list': productItem.indexes.global,
            ...analyticsProperties,
          }}
        />
      ) : null}
      {isRecipe(productItem) ? (
        <RecipeCard
          recipe={productItem}
          eventSource={subcategory?.name || categoryName || eventSource || ''}
          onClick={() => onClick?.(productItem)}
          categoryName={categoryName}
          subcategory={subcategory}
        />
      ) : null}
      {isMarketingCard(productItem) ? (
        <MarketingCard
          cardId={productItem.id}
          key={productItem.id}
          analyticsProperties={{
            'card position in the list': productItem.indexes.global,
            'category name': categoryName ?? undefined,
            'subcategory name': subcategory?.name ?? undefined,
            ...analyticsProperties,
          }}
        />
      ) : null}
    </>
  );
};

export default CardDispatcher;
