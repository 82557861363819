import { SyntheticEvent } from 'react';

import { usePathname } from 'next/navigation';

import Link from '~/src/common/components/Link';
import { useNiceModal } from '~/src/common/services/ModalsManager';
import Tracker, { CardInfoProperties } from '~/src/common/services/Tracker';
import { MarketingCard as MarketingCardType } from '~/src/common/typings/product';
import { isSamePage } from '~/src/common/utils/url';
import { useGetMarketingCardQuery, CarteMarketing } from '~/src/queries/cms/generated/cms';
import { ProductPlaceholder } from '~/src/screens/CatalogContent/ListProductsScreen/layout';

import MarketingCardContent from './MarketingCardContent';

interface Props {
  cardId: MarketingCardType['id'];
  analyticsProperties: CardInfoProperties;
}

const VISIBLE_CLASS = 'product-card';

const MarketingCard = ({ cardId, analyticsProperties }: Props) => {
  const modal = useNiceModal('marketing-modal');
  const pathname = usePathname();
  const { data, isLoading } = useGetMarketingCardQuery({
    card_id: cardId,
  });

  const marketingCard = data?.carteMarketings?.[0];

  if (isLoading || !marketingCard) {
    return <ProductPlaceholder className={VISIBLE_CLASS} id={cardId} key={cardId} />;
  }

  const { link_url, image, Modale_Marketing, keplr_name } = marketingCard ?? {};

  if (!image) {
    return null;
  }

  const infoSlug = Modale_Marketing?.modale_marketing?.info_slug;

  const handleButtonClick = () => {
    Tracker.sendEvent('card marketing click', {
      'content name': keplr_name ?? '',
      'link type': 'marketing modal',
      'link': keplr_name,
      ...analyticsProperties,
    });

    // eslint-disable-next-line @typescript-eslint/no-floating-promises  -- auto-ignored when updating eslint
    if (infoSlug != null) modal.show({ infoSlug });
  };

  const handleLinkClick = () => {
    Tracker.sendEvent('card marketing click', {
      'content name': keplr_name ?? '',
      'link type': 'URL',
      'link': link_url ?? undefined,
      ...analyticsProperties,
    });
  };

  const handleClick = (e: SyntheticEvent) => {
    if (link_url && !infoSlug) {
      handleLinkClick();
    } else if (infoSlug) {
      e.preventDefault();
      handleButtonClick();
    }
  };

  const link = infoSlug ? `/info/${infoSlug}` : link_url;

  return link ? (
    <Link href={link} shallow={!isSamePage(pathname, link)} onClick={handleClick}>
      <MarketingCardContent marketingCard={marketingCard as CarteMarketing} />
    </Link>
  ) : (
    <MarketingCardContent marketingCard={marketingCard as CarteMarketing} />
  );
};

export default MarketingCard;
