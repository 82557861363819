import I18n from '~/src/common/services/I18n';
import constants from '~/src/constants';

// Configururation pour les titres
export const TITLE_SUFFIX = ` - ${constants.BRAND_NAME}`;
export const TITLE_FALLBACK = constants.BRAND_NAME;
export const TITLE_MAX_LENGTH = 70;

// Configuration pour les descriptions
export const DESCR_SUFFIX = ` - ${I18n.t('common.freshness-promise')}`;
export const DESCR_FALLBACK = I18n.t('common.meta-description');
export const DESCR_MAX_LENGTH = 160;

// Configuration pour les images
export const IMAGE_TRANSFORM_BASE = 'ar_1.5,w_800,q_70,c_fill,f_auto,dpr_auto/';
export const IMAGE_TRANSFORM_PRODUCT = `ar_1.25,g_north,c_fill/${IMAGE_TRANSFORM_BASE}`;
export const IMAGE_TRANSFORM_CATEGORY = `${IMAGE_TRANSFORM_BASE}`;
export const IMAGE_FALLBACK =
  'https://res.cloudinary.com/keplr/image/upload/v1583937095/production/mon_marche/opengraph/mon-marche.fr.jpg';
