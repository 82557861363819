import styled from 'styled-components';

import { CARD_RATIO } from '~/src/common/components/GridCard/layout';
import { media } from '~/src/styles-constants';

import ImageObjectFit from '../../ImageObjectFit';

export const Image = styled(ImageObjectFit)`
  position: absolute;
`;

export const Container = styled.article`
  position: relative;
  overflow: hidden;

  aspect-ratio: ${CARD_RATIO};

  width: 100%;
  height: 100%;

  z-index: 2;
  border-radius: ${({ theme }) => theme.layout.borderRadius};
`;

export const MarketingCardTextContainer = styled.div`
  text-align: center;
  color: ${({ theme }) => theme.palette.common.WHITE};
`;

export const MarketingCardHeader = styled.p`
  margin: 0;
  font-family: ${({ theme }) => theme.fonts.secondary};
  font-size: 15px;
  font-weight: bold;
  line-height: 1.1;
  text-align: center;
  ${media.S`
    line-height: 1.54;
    font-size:26px;
  `}
  ${media.L`
    font-size:22px;
  `}
`;

export const MarketingCardDescription = styled.span`
  margin-top: 20px;
  font-size: 12px;
  font-weight: 500;
  text-align: center;
  line-height: 1.1;
  display: inline-block;
  ${media.S`
    line-height: normal;
    font-size: 20px;
  `}

  ${media.L`
    font-size: 16px;
  `}
`;

export const MarketingCardPanel = styled.div`
  position: absolute;
  top: 25%;
  bottom: 0;
  width: 100%;
  padding: 0 5px;
  display: flex;
  flex-direction: column;
  color: ${({ theme }) => theme.palette.common.WHITE};

  ${media.S`
    padding: 0 15px;
    top: 25%;
  `}
`;
