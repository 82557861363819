import React from 'react';

import Link from '~/src/common/components/Link';
import TypeTag from '~/src/common/components/Recipe/TypeTag/TypeTag';
import { ImageFormatIds, ImageParams } from '~/src/common/typings/image';
import { RecipeItem } from '~/src/common/typings/product';
import { getImageUrlFromFormat } from '~/src/common/utils/cloudinary';
import { useRecipeLink } from '~/src/screens/CatalogContent/ContentScreen/hooks';

import * as GS from '../layout';

import * as S from './layout';

interface Props {
  recipe: RecipeItem;
  eventSource: string;
  categoryName?: string;
  subcategory?: { id: string; name: string };
  onClick?: (recipe: RecipeItem) => void;
}

const RecipeCard = ({ recipe, categoryName, subcategory, eventSource, onClick }: Props) => {
  const { name, images, slug, recipeType } = recipe;
  const imageUrl = getImageUrlFromFormat(
    images[0],
    ImageFormatIds.CardRatio,
    ImageParams.listingCard,
  );

  const { as, query } = useRecipeLink(slug, {
    category: categoryName,
    subcategory: subcategory?.name,
    eventSource,
  });

  return (
    <GS.Container onClick={() => onClick?.(recipe)}>
      <GS.Image src={imageUrl} $objectFit="cover" $objectPosition="center" alt={name} />
      <GS.Content>
        <Link href={{ pathname: as, query }} as={as}>
          <S.TopSectionContainer>
            <GS.InformationContainer>
              <TypeTag recipeType={recipeType} />
              <S.ProductName>{name}</S.ProductName>
            </GS.InformationContainer>
          </S.TopSectionContainer>
        </Link>
      </GS.Content>
    </GS.Container>
  );
};

export default React.memo(RecipeCard);
