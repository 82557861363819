import styled from 'styled-components';

import { ProductName as BaseProductName } from '~/src/common/components/GridCard/layout';

export const ProductName = styled(BaseProductName)`
  margin-top: 8px;
`;

export const TopSectionContainer = styled.div`
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
`;
