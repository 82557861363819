import styled, { css } from 'styled-components';

import { CARD_RATIO } from '~/src/common/components/GridCard/layout';
import Link from '~/src/common/components/Link';
import { getMediaQuery } from '~/src/common/utils/style';
import { breakpoints, media } from '~/src/styles-constants';

export const CATEGORIES_MOBILE_MENU_HEIGHT = 120;
export const RECIPES_CATEGORY_MOBILE_MENU_HEIGHT = 80;

export const CategoryPageContainer = styled.div`
  @media ${getMediaQuery(0, breakpoints.XL)} {
    padding-top: 0;
    padding-bottom: env(safe-area-inset-bottom);
  }
`;

export const CategoryDescriptionContainer = styled.p`
  display: none;
  margin-top: 0;
  margin-bottom: 16px;

  ${media.M`
    display: block;
  `}
`;

export const RefreshButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  padding: 30px;
`;

export const ProductPlaceholder = styled.div`
  aspect-ratio: ${CARD_RATIO};
  border-radius: 13px;

  background: ${({ theme }) => theme.palette.common.PLACEHOLDER};
`;

/*
 * Menu mobile pour les page catégories
 *
 */
export const CategoriesMobileMenu = styled.div`
  display: flex;
  flex-direction: column;

  width: 100%;
`;

export const MainCategoriesMobileMenu = styled.div<{ $isHidden: boolean }>`
  display: flex;
  overflow: auto;
  padding: 0 8px 8px;

  ${({ $isHidden }) => ($isHidden ? 'display: none;' : null)}

  /* Chrome & Safari */
  &::-webkit-scrollbar {
    display: none;
  }

  /* Firefox */
  scrollbar-width: none;

  ${media.XL`
    display: none;
  `}
`;

const activeItemStyle = css`
  font-weight: 500;
  background-color: ${({ theme }) => theme.palette.common.PRIMARY_HOVER};
`;

export const MainCategoryMobileItem = styled(Link)`
  height: 24px;

  &:not(:last-child) {
    margin-right: 8px;
  }
`;

export const MainCategoryMobileItemText = styled.span<{ $isActive: boolean }>`
  font-size: 14px;
  line-height: 16px;
  white-space: nowrap;
  color: ${({ theme }) => theme.palette.common.WHITE};
  padding: 4px 8px;
  border-radius: 4px;
  display: block;

  ${({ $isActive }) => ($isActive ? activeItemStyle : '')};

  @media (hover: hover) and (pointer: fine) {
    &:hover {
      ${activeItemStyle}
    }
  }
`;

export const SubCategoriesMobileMenu = styled.div`
  display: flex;
  overflow: auto;
  padding: 8px;

  background-color: ${({ theme }) => theme.palette.common.BACKGROUND_1};

  &::-webkit-scrollbar {
    display: none;
  }

  ${media.XL`
    display: none;
  `}
`;

const activeSubItemStyle = css`
  font-weight: 500;
  background-color: ${({ theme }) => theme.palette.primary};
  color: ${({ theme }) => theme.palette.common.WHITE};
`;

export const SubCategoryMobileItem = styled.a<{ $isActive: boolean }>`
  &:not(:last-child) {
    margin-right: 8px;
  }

  font-size: 12px;
  line-height: 16px;
  white-space: nowrap;
  padding: 4px 8px;
  border-radius: 4px;
  color: ${({ theme }) => theme.palette.common.TEXTS};
  background-color: ${({ theme }) => theme.palette.common.WHITE};

  &:focus,
  &:hover {
    color: ${({ theme, $isActive }) =>
      $isActive ? theme.palette.common.WHITE : theme.palette.common.TEXTS};
  }

  ${({ $isActive }) => ($isActive ? activeSubItemStyle : '')};

  /* Chrome */
  &::-webkit-scrollbar {
    display: none;
  }
`;

export const SubCategoryFooterContentContainer = styled.div`
  margin-top: 56px;
`;

export const SubCategoryHeaderContentContainer = styled.div`
  margin-bottom: 16px;
  display: none;

  ${media.XL`
    display: block;
  `}
`;
