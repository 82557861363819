import { applyTransformsToIodUrl, replaceCloudinaryUrlByIod } from '~/src/common/utils/cloudinary';
import { sanitize, truncate } from '~/src/common/utils/text';
import constants from '~/src/constants';

import {
  DESCR_FALLBACK,
  DESCR_MAX_LENGTH,
  DESCR_SUFFIX,
  IMAGE_FALLBACK,
  IMAGE_TRANSFORM_BASE,
  TITLE_FALLBACK,
  TITLE_MAX_LENGTH,
  TITLE_SUFFIX,
} from './constants';

export const getSeoTitle = (text: Nullable<string>, noSuffix?: boolean) => {
  if (text == null) return TITLE_FALLBACK;

  const suffix = noSuffix ? '' : TITLE_SUFFIX;
  const length = TITLE_MAX_LENGTH - suffix.length;
  const content = truncate(sanitize(text), length);

  return `${content}${suffix}`;
};

export const getSeoDescr = (descr: Nullable<string>, noSuffix?: boolean) => {
  const suffix = noSuffix ? '' : DESCR_SUFFIX;
  const length = DESCR_MAX_LENGTH - suffix.length;
  const content = truncate(sanitize(descr ?? DESCR_FALLBACK), length);

  return `${content}${suffix}`;
};

export const getSeoImage = (image: Nullable<string>, transform?: string) => {
  const content = replaceCloudinaryUrlByIod(image ?? IMAGE_FALLBACK);
  return applyTransformsToIodUrl(content, transform ?? IMAGE_TRANSFORM_BASE);
};

export const getCanonical = (url: Nullable<string>) => {
  if (url == null) return null;
  if (/^https?:/.test(url)) return url;
  return `${constants.SITE_URL}${url}`;
};
